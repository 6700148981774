// Following styles apply to lista-targow.html only
#xc-list, #xc-generate {

    .section-intro {
        padding-bottom: $default-spacing + 10px;;
        .xc-content {}
        h3 { border-bottom: 1px solid $light-grey; }
        .xc-spacing {
            padding-top: 10px;
            clear: both;
        }
        .btn.pull-right {
            margin-top: 10px;
            margin-left: 5px;
        }
    }
    .xc-category {
        @include transition(0.25s);
        padding: 7px 10px;
        font-weight: bold;
        background: #fff;
        color: #555;
        box-shadow: inset 0 0 0 1px #fff;
        cursor: pointer;
        .glyphicon { color: $light-grey; }        
        &:hover {
            color: $light-blue;
            box-shadow: inset 0 0 0 1px $light-grey;
        }        
        &.selected {
            background: $light-blue;
            color: #fff;
            &:hover { background: $lighter-blue; }
        }
    }
    .xc-form-separator {
        margin: 10px 0 15px;
        border-top: 1px solid $light-grey;
        text-align: center;
    }
    .xc-industry {
        //&.additional { display: none; }      
        input[type="checkbox"] { margin-right: 4px; }
        .xc-industry-name { cursor: pointer; }
        .xc-count { color: $grey; }
    }
    .xc-industry-name {
        @include transition(0.15s);
    }
    .custom-selection {
        .xc-category {
            color: $grey;
            .glyphicon { color: #fff; }
            &.selected {
                color: #fff;
            }
        }
        input[type="checkbox"] + .xc-industry-name { color: $grey; }
        input[type="checkbox"]:checked + .xc-industry-name { color: #333; }
    }
    .xc-form h3 { margin-top: 30px; }
    .xc-form p.industries-options {
        margin: $default-spacing 0 auto;
        font-weight: bold;
        cursor: default;
        & > a { 
            cursor: pointer !important;
            color: #428bca;
            &:hover { text-decoration: none; }
        }
        .glyphicon { font-size: 85%; }
    }
    .industries-toggle {
        .show-more,
        .show-less { 
            display: none;
        }

        &.show-more {
            .show-more { display: inline; }
        }
        &.show-less {
            .show-less { display: inline; }
        }
    }
    #show-finished {
        margin: $default-spacing auto;
    }
    .xc-btn-primary[type="submit"] {
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
    }
    .additional-search {}

    .xc-search-pagination {
        padding: 0;
        > .pagination {
            margin: $pagination-spacing-sm 0;
        }
    }
    .xc-search-count {
        margin: $pagination-spacing-sm 0;
        padding: 6px 14px;
    }
    .xc-event-list {
        .xc-tradefair-link {
            text-decoration: none;
        }
    }
    .xc-event {
        @include transition(0.2s);
        position: relative;
        padding: 0 0 0;
        margin-bottom: $event-spacing;
        border: 1px solid $light-grey;
        background: $white;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        & > .xc-equal-column {
            padding-top: $event-padding;
        }
        .xc-event-details {
            color: $default-grey;
            &:hover { color: $default-grey; }
        }
        .xc-event-id-container {
            @include transition(0.2s);
            border-right: 1px solid $light-grey; 
            background: #eee;
            background: rgba(255,255,255,.4);
        }
        .xc-event-id {
            text-align: center;
        }
        .xc-event-name {
            border-bottom: 1px solid $lighter-grey;
            font-weight: bold;
            &:hover {
                border-bottom: 1px solid $light-grey;
                color: $dark-grey;
            }
        }
        .xc-label {
            padding-right: 6px;
            font-weight: bold;
            color: $grey;
        }
        .fb_iframe_widget {
            margin-top: 4px;
        }

        &:hover {
            border-color: $lighter-blue;
            .xc-event-id-container { 
                background: $lighter-blue;
                color: #fff; 
            }
        }
    }

    .xc-link {
        color: $blue;
        cursor: pointer;
        @include transition(0.15s); 
        &:hover {
            color: $light-blue;
        }
        .glyphicon {
            opacity: .4;
        }
    }
    .xc-selected > .xc-event-id-container {
        background: #fc7;
        @include transition(0.35s);
    }

    .no-results-container {
        padding: $default-spacing;
        margin-bottom: 50px;
        border: 1px solid #ddd;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background: rgba(240,240,240,.5);
    }
    .no-results-image {
        height: 150px;
        width: 150px;
    }
    .no-results-text {
        margin: 0 0 0 30px;
        line-height: 150px;
        font-size: 24px;
        color: #999;
    }
/*
    .btn.xc-more-button {
        display: block;
        width: 100%;
        padding: $event-spacing;
        border: 1px solid $grey;
        border-radius: 0;
        font-width: bold;
        text-align: center;
        background: $grey;
        color: $white;
        &:visited,
        &:active,
        &:hover {
            background: #aaa;
        }
    }

    #quicksearch {
        &.affix {
            top: $header-height + $default-spacing;
            width: inherit;
            height: inherit;

            h3 {
                margin-top: 0;
            }
        }
        .btn.pull-right {
            margin-top: 10px;
            margin-left: 5px;
        }
    }
*/
    .xc-navigate-top {
        padding: $pagination-spacing 0;
    }

    aside.xc-banner {
        padding-top: $default-spacing;
        padding-bottom: $default-spacing;
        .xc-affix {
            &.affix-top {}
            &.affix {
                position: fixed;
                top: 80px;
            }
            &.affix-bottom {}
        }
        img { 
            max-width: 100%;
            max-height: 500px;
        }
    }

    @media (max-width: $screen-md-min) {
    .xc-form {
        .xc-form-month,
        .xc-form-city,
        .xc-form-name {
            padding-bottom: 20px;
        }
    }
    }
}

.section-event-list {
    border-top: 1px solid #ddd;
    background: url('/images/background-light.jpg');
    .xc-content {}
}