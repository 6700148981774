//.xc-footer-container {
.xc-page-footer {
    width: 100%;
    color: $grey;
    border-top: 1px solid $light-grey;  
    //box-shadow: 0 0 6px rgba(0,0,0,.25);
    background-color: #fbfbfb;
    background-image: url('/images/china.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;

    .contact {
        padding-top: 20px;
        padding-bottom: 20px;
        .xc-h3 { margin-top: 10px; }
        .phone {
            @include text-shadow(0 0 1px $shadow-grey);
            font-weight: bold;
            color: $orange;
        }
        .email {
            @include text-shadow(0 0 1px $shadow-grey);
            color: $blue;
        }
        .glyphicon {
            font-size: 80%;
            color: #ccc;
        }
    }

    .footer-links {
        width: 100%;
        //padding-bottom: 45px;
        margin-bottom: 45px;
        //border: 3px solid rgba(0,0,0,.05);
        border: 1px solid #ddd;
        background: rgba(248,248,248,.7);
        .h-4 {
            margin-top: 10px;
            padding-bottom: 5px;
            //border-bottom: 3px solid rgba(0,0,0,.05);
            border-bottom: 1px solid #ddd;
        }
        a {
            color: #666;
            &:hover {
                text-decoration: none;
                border-bottom: 1px solid #999;
            }
        }
    }

    ul {
        list-style: none;
        list-style-type: none;
    }

    .languages-container {
        position: absolute;
        left: $default-spacing;
        bottom: 10px;
        padding: 0 15px;
        //border: 1px solid #d8d8d8;
        //background: rgba(248, 248, 248, 0.7);
        .language-flag {
            width: 30px;
            height: 15px;
            margin-right: 4px;
            outline: 1px solid #999;
            background-image: url('/images/languages.jpg');
            opacity: .7;
            &.language-en { background-position: 0 0; }
            &.language-jp { background-position: 0 -15px; }
            &.language-sk { background-position: 0 -30px; }
            &.language-de { background-position: 0 -45px; }
            &.language-fr { background-position: 0 -60px; }
            &.language-es { background-position: 0 -75px; }
            &.language-br { background-position: 0 -90px; }
            &.language-ru { background-position: 0 -105px; }
            &.language-pl { background-position: 0 -120px; }

            &:hover { opacity: 1; }
        }
    }

    p.copyright {
        padding-top: 10px;
        text-align: center;
        a { color: #999; }
    }

    @media (max-width: $screen-md-min) {
        background: #f3f3f3;
    }

}

.xc-footer-linkbox {
    margin-bottom: 45px;
    border: 1px solid #ddd;
    background: rgba(248, 248, 248, 0.7);

    .xc-footer-h4 {
        margin-top: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ddd;
    }
}