// Following styles apply to targi-w-kantonie.html only
#xc-canton {

    .img-fullwidth-container {
        margin: $default-spacing auto;
    }
    .img-fullwidth {
        width: 100%;
        border: 1px solid #ccc;
        -webkit-box-shadow: 0 0 6px rgba(0,0,0,.25);
        -moz-box-shadow: 0 0 6px rgba(0,0,0,.25);
        box-shadow: 0 0 6px rgba(0,0,0,.25);
    }

    .xc-schedule-header {
        margin-top: 40px;
        .glyphicon { color: $grey; }
    }

    table.xc-phase-details {
        border-collapse: separate;
        border-spacing: 0;
        th {
            padding: 10px 8px 8px;
            background: #333;
            border-color: #333;
            color: #fff;
        }
        td[rowspan] {
            font-weight: bold;
        }
    }

    aside.xc-banner {
        padding-top: $default-spacing;
        padding-bottom: $default-spacing;
        .xc-affix {}
        img { 
            max-width: 100%;
            max-height: 500px;
        }
    }

    .booking-results-container {}

}