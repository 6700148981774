/* General styles to every box */

.banner-box {
	text-align: center;
	padding: 0px;
	font-family: LatoBlack,Lato,Tahoma,sans-serif;
	font-size: 16px;
	padding-top: 10px;
}

.banner-box .btn-box {
	width: 100%;
	padding: 10px 0px;
}

.banner-box .banner-header {
	font-size: 20px;
}

.banner-box .banner-subheader {
	font-size: 16px;
}

/* Styles for ad respectedly */

.banner-box.report {
	background: lightblue;
	background-image: url('/images/4banner/report.png');
	background-position:  center center;
	background-size: cover;
	color: #fff;
	text-shadow: -1px 0 #656565, 0 1px #656565, 1px 0 #656565, 0 -1px #656565;
	border-left: 2px solid #d0d0d0;
	border-right: 2px solid #d0d0d0;
        border-top: 2px solid #d0d0d0;
}

.banner-box.report .btn-box {
	border-top: 2px solid #3257a6;
	background: #2d3c95;
	text-shadow: none;
}

.banner-box.logo {
	background: blue;
	background-image: url('/images/4banner/logo.png');
	background-position:  center center;
	background-size: cover;
	color: #fff;
	text-shadow: -1px 0 #656565, 0 1px #656565, 1px 0 #656565, 0 -1px #656565;
	border-left: 2px solid #d0d0d0;
	border-right: 2px solid #d0d0d0;
        border-top: 2px solid #d0d0d0;
}

.banner-box.logo .btn-box {
	border-top: 2px solid #3257a6;
	background: #2d3c95;
	text-shadow: none;
}

.banner-box.training {
	background: red;
	background-image: url('/images/4banner/training.png');
	background-position:  center center;
	background-size: cover;
	color: #fff;
	text-shadow: -1px 0 #656565, 0 1px #656565, 1px 0 #656565, 0 -1px #656565;
	border-left: 2px solid #d0d0d0;
	border-right: 2px solid #d0d0d0;
        border-top: 2px solid #d0d0d0;
}

.banner-box.training .btn-box {
	border-top: 2px solid #ca3b33;
	background: #ca190f;
	text-shadow: none;
}

.banner-box.ccc {
	background: #fff;
	background-image: url('/images/4banner/ccc.png');
	background-position:  center center;
	background-size: cover;
	color: #000;
	text-shadow: -1px 0 #ddd, 0 1px #ddd, 1px 0 #ddd, 0 -1px #ddd;
	border-left: 2px solid #d0d0d0;
	border-right: 2px solid #d0d0d0;
        border-top: 2px solid #d0d0d0;
}

.banner-box.ccc .btn-box {
	border-top: 2px solid #656565;
	background: #000;
	text-shadow: none;
}

/* General button styles */

.banner-box .btn {
	padding-left: 18px;
	padding-right: 18px;
	font-family: LatoBlack,Lato,Tahoma,sans-serif;
	font-size: 2rem;
	font-weight: bolder;
	border: 1px solid;
	border-top-color: rgba(255,255,255,.24);
	border-bottom-color: rgba(0,0,0,.16);
	border-left-color: rgba(50,50,50,.08);
	border-right-color: rgba(50,50,50,.08);
	outline: none;
}

/* Different color versions of button */

.banner-box .btn.orange {
	background: #ffc06d;
    background: -webkit-linear-gradient(270deg,#ffa746,#ff8b1c);
    background: linear-gradient(180deg,#ffa746,#ff8b1c);
    color: #fff;
    outline: none;
}

.banner-box .btn.orange:hover {
    background: #ffae56!important;
    color: #fff;
    outline: none;
}

.banner-box .btn.orange:active {
	background: #ffae56!important;
	background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    outline: none;
}

#fourbanner {
}