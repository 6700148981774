// Sticky footer
// Automatically adjust page height to push footer to bottom.
html,
body {
    min-height: 100%;
}
body {
    height: auto;
    padding-top: $header-height;
    &.stickyFooter {
        height: 100%;
        padding-bottom: $footer-height;
    }
}
.xc-page-container {
    min-height: 100%;
    height: auto;
}
.xc-banner-container {
    min-height: 100%;
    height: auto;
}
.xc-page-content-container {
    //padding-bottom: $footer-height;
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 30px;
}
//.xc-footer-container {
.xc-page-footer {
    position: relative; //absolute
    height: auto; //$footer-height;
    clear: both;
    &.stickyFooter {
        position: absolute;
        bottom: 0;
        margin-top: -$footer-height;
    }
}

// Bottom navigation
.xc-bottom-nav {
    margin-top: $default-spacing;
    border: 1px solid #ddd;
    background: #fcfcfc;
    text-align: center;
    p.h4 {
        margin-top: 10px;
    }
    .glyphicon { font-size: 80%; }
    a {
        @include transition(0.2s);
        &:hover {
            text-decoration: none;
        }
    }
}

// Reset list styles
ul {
    list-style: none;
    padding-left: 0;
}

// Equal height columns
/* Alternative solution
.xc-equal-container {
    display: table;
    & > .xc-equal-column {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}
*/
/* Working solution */
.xc-equal-container {
    overflow: hidden;
    & > .xc-equal-column {
        margin-bottom: -1000px;
        padding-bottom: 1000px;
    }
}

// Bootstrap 5 equal columns
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: $screen-sm-min) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: $screen-md-min) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: $screen-sm-min) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

// Bootstrap Affix fix
.affix-bottom {
    position: relative;
}

// Monthpicker fix
#ui-datepicker-div {
    padding: 0;
    border: 0;
    margin-top: 3px;
}
.ui-datepicker-calendar {
    display: none;
}

// Helper classes
.link { cursor: pointer; }
.blue { color: $blue; }

.center-text {
    text-align: center;
}

.desaturated {
    @include transition(0.1s);
    @include greyscale;
    &:hover {
        filter: none;
        -webkit-filter: grayscale(0);
    }
}


// Pagination
.pagination li.disabled a { cursor: default !important; }

// Images - vertical center
span.img-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
    margin-right: -4px;
}

// Select fields
.xc-form {
    select {                  
        &.xc-default-option { color: $grey; }
        & > option { color: $dark-grey; }
        & > option[default] { color: $grey; }
    }
}

// Buttons
.xc-btn {
    border-radius: 0;
}
.xc-btn-lg {
    border-radius: 4px;
}
.xc-btn-grey {
    @include linear-gradient($lighter-grey, $light-grey);
    @include text-shadow(0 -1px 1px $white);
    border: 1px solid $light-grey;
    //color: $black;
    &:visited,
    &:active,
    &:hover {
        border-color: $light-grey;
        background: $light-grey;
        //color: $black;
    }
}
.xc-btn-blue {
    @include linear-gradient($lighter-blue, $light-blue);
    @include text-shadow(0 -1px 1px mix($blue, $shadow-grey));
    border: 1px solid mix($light-blue, $lighter-blue);
    color: $white;
    &:visited,
    &:active,
    &:hover {
        border-color: $lighter-blue;
        background: $lighter-blue;
        color: $white;
    }
}
.xc-btn-orange {
    border-color: $lighter-orange;
    background: $lighter-orange;
    @include linear-gradient($lighter-orange, $light-orange);
    @include text-shadow(0 -1px 1px mix($orange, $shadow-grey));
    border: 1px solid mix($light-orange, $lighter-orange);
    color: $white;
    &:visited,
    &:active,
    &:hover {
        border-color: $lighter-orange;
        background: $lighter-orange;
        color: $white;
    }
}
// Close button
.close {
    &.xc-set {
        color: $blue;
        opacity: .9;
    }
}

// Text shadow
.xc-text-shadow {
    @include text-shadow(0 -1px 1px $shadow-grey);
}

.xc-show-sm {
    display: none;
    @include breakpoint(sm) {
        display: initial;
    }
}
.xc-show-md {
    display: none;
    @include breakpoint(md) {
        display: initial;
    }
}
.xc-show-lg {
    display: none;
    @include breakpoint(lg) {
        display: initial;
    }
}

// Main content sections
.xc-section {
    position: relative;
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;
    margin-bottom: $section-spacing;
    //border-bottom: 1px solid $light-grey;
    // Clearfix after each section
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
.xc-section-content {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    &.fullwidth {
        max-width: none;
    }
    .xc-content {
        
    }
}

#flags-container {
    position: relative;
}

#flags {
    a {
        background-image: url("/images/flags.png");
        background-repeat: no-repeat;
        padding-left: 36px;
        text-decoration: none;
            &.en {
                background-position: 2px 0px;
            }
            &.ja {
                background-position: 2px -32px;
            }
            &.ko {
                background-position: 2px -64px;
            }
            &.de {
                background-position: 2px -96px;
            }
            &.fr {
                background-position: 2px -128px;
            }
            &.es {
                background-position: 2px -160px;
            }
            &.pt {
                background-position: 2px -192px;
            }
            &.ru {
                background-position: 2px -224px;
            }
            &.pl {
                background-position: 2px -256px;
            }
            &.ar {
                background-position: 2px -288px;
            }
    }
}

#navbarDropdown {
    span {
        background-image: url("/images/flags.png");
        background-repeat: no-repeat;
        padding-left: 36px;
        text-decoration: none;
        background-position: 2px -8px;
            &.en {
                background-position: 2px -8px;
            }
            &.ja {
                background-position: 2px -40px;
            }
            &.ko {
                background-position: 2px -72px;
            }
            &.de {
                background-position: 2px -104px;
            }
            &.fr {
                background-position: 2px -136px;
            }
            &.es {
                background-position: 2px -168px;
            }
            &.pt {
                background-position: 2px -200px;
            }
            &.ru {
                background-position: 2px -232px;
            }
            &.pl {
                background-position: 2px -264px;
            }
            &.ar {
                background-position: 2px -296px;
            }
    }
}


#popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 1000;
    display: none;
    padding: 10px 15px;
}

#popup .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 3px solid rgba(0,0,0,0.5);
    padding: 20px 15px;
    border-radius: 10px;
    h2 {
        text-align: center;
    }
    .btn.orange {
        display: block;
        background: #ffc06d;
        background: -webkit-linear-gradient(270deg, #ffa746, #ff8b1c);
        background: linear-gradient(180deg, #ffa746, #ff8b1c);
        color: #fff;
        outline: none;
        width: 49%;
        min-width: 227px;
        margin: 0px auto;
        margin-bottom: 15px;
        &:hover {
            background: #ffae56 !important;
            color: #fff;
            outline: none;
        }
        &:active {
            background: #ffae56 !important;
            background-image: none;
            -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            outline: none;
        }
    }
    a.logo {
        display: inherit;
        text-align: center;
    }
}

#popup .popup .close-circle {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    border: 3px solid rgba(0,0,0,0.5);
    border-radius: 15px;
    background: white;
    opacity: 0.5;
    &:hover {
        opacity: 0.8;
    }
}