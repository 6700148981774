// Following styles apply to "/targi/{id}" pages
.xc-page-trade-fair {
    background: url('/images/background-light.jpg');
    
    .fb_iframe_widget {
        margin-top: 4px;
    }

    .xc-section-content { background: rgba(255, 255, 255, 0.75); }
    div.xc-content { 
        background: #fff;
        padding-bottom: $default-spacing;
        border: 1px solid #ddd;
    }
    aside.xc-content {

        h4 {
            margin-top: $default-spacing;
            margin-bottom: 5px;
        }
    }

    .xc-label {
        padding-right: 4px;
        color: $grey;
    }
    .xc-searchbox-container {
        width: 350px;
    }
    #booking-searchbox {
        width: 350px;
        overflow: hidden;
        border-bottom: 1px solid #ddd;
        margin-bottom: 51px;
    }
    #examinechina-banner {
        width: 350px;
        border: none;
        border-top: none;
        margin-top: -5px;
        background: #fff;
        .partner-logo {
            @include transition(0.2s);
            padding: 20px 0;
            opacity: .65;
            &:hover { opacity: 1; }
        }
    }
    .xc-map {
        height: 360px;
        margin-left: 350px + $default-spacing;
        margin-bottom: $default-spacing;
        border: 1px solid #ddd;
        text-align: center;
        background: #eee;
        h4 {
            color: #fff;
            line-height: 310px;
        }
    }

    aside.xc-content {
        .xc-related {
            .glyphicon { color: #bbb; }
        }
    }
    aside.xc-content a {
        color: $grey;
        .xc-related-event {
            @include transition(0.15s);
            margin-top: $default-spacing;
            //padding-bottom: $default-spacing;
            border: 1px solid #ddd;
            background: #fff;
            &>div {
                padding: 2px 5px;
            }
            .xc-event-name {
                @include transition(0.15s);
                border-bottom: 1px solid #ddd;
                color: #111;
                background: $lighter-grey;
            }
            .xc-event-date {}
            .xc-event-industries {}
            .glyphicon {
                color: #bbb;
                width: 20px;
            }
        }

        &:hover {
            text-decoration: none;
            .xc-related-event {
                border-color: $light-blue;
            }
            .xc-event-name {
                color: #fff;
                background: $light-blue;
            }
        }
    }

//  BOOKING.COM IFRAME
    .booking-results-container {
        min-height: 100%;
        height: 100%;
    }
    #iframe_booking_searchbox {}
    #iframe_booking_results {
        min-height: 100%;
        height: 100%;
    }
    #iframe_booking_banner {}

    @media (max-width: $screen-md-min) {
    .xc-searchbox-container {
        width: 100%;
        margin-bottom: $default-spacing;
        #booking-searchbox {
            width: 100%;
            height: 180px;
        }
        #plane-searchbox {
            width: 100%;
        }
    }
    #xc-gmap {
        width: 100%;
        margin-left: 0;
        margin-top: $default-spacing;
    }
    }

}