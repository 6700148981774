// Following styles apply to lody-do-chin.html only
#xc-flights {

    .img-fullwidth-container {
        margin: $default-spacing auto;
    }
    .img-fullwidth {
        width: 100%;
        //border: 1px solid #ccc;
        border: 5px solid rgba(255,255,255,.2);
        outline: 1px solid #ddd;
        -webkit-box-shadow: 0 0 6px rgba(0,0,0,.25);
        -moz-box-shadow: 0 0 6px rgba(0,0,0,.25);
        box-shadow: 0 0 6px rgba(0,0,0,.25);
    }

    .xc-section-content > aside {
        padding-top: 56px;
        padding-bottom: $default-spacing;
    }

    #airlines-logos {
        margin-top: $default-spacing;
    }
    .xc-airlines-container {
        border: 1px solid #ddd;
        .xc-airlines-logo {
            @include transition(0.25s);
            @include greyscale(1);
            opacity: 0.65;
            &:hover {
                @include greyscale(0);
                opacity: 1;
            }
        }
        img { 
            max-width: 100%;
            max-height: 100px;
        }
    }

}