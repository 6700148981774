// Following styles apply to wyszukanie-chinskich-firm.html only
#xc-findChineseFirms {

    .xc-list {
        
    }

    .xc-cta {
        margin-top: $default-spacing;
        .glyphicon { 
            font-size: 75%;
            opacity: .5;
        }
    }

    .xc-aside-banner {
        padding-top: $default-spacing;
    }

    .xc-frame {
        //@include box-shadow(0 0 10px $shadow-grey);
        margin-top: $default-spacing;
        margin-bottom: $default-spacing;
        overflow: hidden;
        outline: 1px solid #ddd;
        max-height: 500px;
        img {
            max-width: 100%;
            max-height: 500px;
        }
    }

}