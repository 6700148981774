.xc-header-container {}
.xc-page-header {
    width: 100%;
    margin: 0;
    border-radius: 0;
    background: #fff;
    border-bottom: 1px solid $light-grey;
    color: $grey;

    .navbar-brand {
        font-weight: bold;
        color: $dark-grey;
    }
    li > a {
        @include transition(0.3s);
        border: none;
        //border-bottom: 2px solid transparent;
        color: $dark-grey; //grey
        cursor: pointer;
        .glyphicon {
            margin-right: 4px;
            color: $grey;          
        }
        &:hover {
            border-color: $light-blue;
            //background: #fff;
            color: $light-blue !important; //$light-blue;
            .glyphicon { color: $light-blue; }
        }
    }
    li.contact {
        margin-right: 100px;
        margin-top: 16px;        
        line-height: $header-height;
        .glyphicon { 
            margin-right: 3px;
            color: $light-blue;
        }
        .phone {
            font-weight: bold;
            color: $default-grey; //$blue;
        }
        .prefix {
            font-weight: normal;
            color: grey;
        }
    }
}

@media (max-width: $screen-md-min) {
.xc-page-container { padding-top: 0; }
.xc-page-header {
    .xc-mobile-fluid {
        display: block;
        float: left;
        width: 100%;
        border-top: 1px solid #ddd;
    }
    .xc-mobile-li {
        display: block;
        float: left;
        width: 50%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: $default-spacing;
        padding-right: $default-spacing;
        border-bottom: 1px solid #eee;
        .glyphicon { margin-right: 2px; }
        &:hover {
            .glyphicon { color: $light-blue; }
        }
    }
    .xc-mobile-contact {
        position: absolute;
        top: 0;
        right: 0;
    }
}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
.xc-page-header {
    .xc-mobile-li {
        width: 33.33%;
        padding-left: 0;
        padding-right: 0;
    }
}
}