// Following styles apply to index.html only
#xc-homepage {

    .section-intro {
        @include background-full('/images/background.jpg');
        .xc-content {
            min-height: 360px;
            margin-top: 60px;
            margin-bottom: 30px;
        }
        .xc-share {
            margin-top: 5px;
            margin-bottom: 5px;
            height: 20px;
            text-align: center;
        }
        .xc-h1 {
            margin-top: 0; //70px;
            margin-bottom: 4px;
            font-size: 4em;
            font-family: Tahoma;
            line-height: 1;
            font-weight: bolder;
            .xc-years {
                @include text-shadow(0 -1px 1px $shadow-grey);
                font-size: 68%;
                color: $light-blue;
            }
        }
        .xc-h2 {
            //text-align: center;
            margin-top: 0;
            //padding-left: 110px;
            font-size: 2.4em;
            line-height: 1;
            //color: $white;
        }
        #index-form-container {
            padding-top: 45px;
            padding-bottom: 45px;
            background: #fff;
            background: rgba(255,255,255,.92);
            border: 1px solid #ddd;
            box-shadow: 0 2px 24px rgba(0,0,0,.2);
            flex-basis: auto;
        }
        .search-container {
            margin-top: 30px;
            padding: $default-spacing 0;
        }
        .xc-form {
            & > .form-group {
                & > select, & > input {
                    width: 100%;
                }
            }
            .xc-close-right {
                position: absolute;
                top: 0px;
                right: 15px;
            }
            .xc-form-input-month, .xc-form-input-name {
                width: 100%;
            }
        }
        .xc-form-submit-container {
            padding: $default-spacing 0;
            .glyphicon {
                margin-right: 4px;
            }
        }
    }

    section.section-description {
        background: #fcfcfc;

        .xc-content { padding-bottom: $default-spacing; }
    }

    .section-links {
        .link {
            @include transition(0.3s);
            height: 180px;
            padding-bottom: $default-spacing;
            text-align: center;
            outline: 1px solid #fff;
            overflow: hidden;
            display: block;
            h3 {
                &.xc-no-break {
                    line-height: 2.2em;
                    white-space: nowrap;
                }
                &.xc-break { line-height: 1.1em; }
            }
            .glyphicon {
                @include transition(0.3s);
                margin-top: 5px;
                font-size: 50px;
                color: #ddd;
            }

            &:hover {
                outline-color: #ddd;
                .glyphicon { color: $light-blue; }
            }        
        }
    }

    @media (max-width: $screen-md-min) {
    .section-intro {
        background: $lighter-blue;
        .xc-years {
        }
        .form-group {
            padding-bottom: 20px;
        }
    }
    }
    
    .xc-content {
        margin-bottom: 15px;
    }
    
    .banner {
        text-align: center;
        margin: 10px auto;
    }
}