// Global variables
@import 'globals/variables';

// Default content styles
@import 'globals/content';
@import 'globals/popout';

// Partials
@import 'includes/header';
@import 'includes/footer';
@import 'includes/4banner';

// Page-specific styles
@import 'pages/index';
@import 'pages/lista-targow';
@import 'pages/targi';
@import 'pages/kanton';
@import 'pages/weryfikacja-firmy';
@import 'pages/wyszukanie-firmy';
@import 'pages/hotele';
@import 'pages/partnerzy';
@import 'pages/loty-do-chin';

// Hotfixes
@import 'hotfix';

// Debug tools
//div, section, header, footer, aside, article { outline: 1px dashed #888; }
//ol, ul, li, table, tr, td, form, input { outline: 1px dotted #888; }