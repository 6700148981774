// Following styles apply to partnerzy.html only
#xc-hotels {

    .booking-searchbox {
        height: 180px;
        margin-top: 25px;
        background: #eee;
    }

    .booking-results-container {
        margin-top: $default-spacing;
    }

    aside.xc-banner {
        padding-top: $default-spacing;
        padding-bottom: $default-spacing;
        img { 
            max-width: 100%;
            max-height: 480px;
        }
    }

}