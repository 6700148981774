.popout-backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0,0,0,0.8);
}

.popout-box {
  margin-top:200px;
}

.popout-close {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  background: rgba(0,0,0,0.2);
  border-radius: 25px;
  cursor: pointer;
}

.popout-bg {
  background-color: #fbfbfb;
  border-radius: 10px;
  padding: 20px;
  background-image: url("/images/popout/training/china.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  h1 {
    font-weight: 500;
    font-size: 28px;
    color: rgb(0, 123, 255);
    text-align: center;
  }
}



.popout-icon-box {
  margin-top: 22px;
  margin-bottom: 16px;
  text-align: center;
}

.popout-icon {
  background: rgb(0, 123, 255);
  padding: 28px;
  border-radius: 65px;
  display: inline-block;
}