// Bootstrap breakpoints
$screen-lg-min: 1200px;
$screen-md-min: 992px;
$screen-sm-min: 768px;

// Global dimensions
$header-height: 50px;
$footer-height: 300px; //250
$section-spacing: 0px;
$content-max-width: 1200px;
$default-spacing: 15px; // Standard Bootstrap spacing
$pagination-spacing-sm: 10px; // Bootstrap padding
$pagination-spacing: 20px; // Bootstrap padding

// Page specific dimensions
// ./index
// ./lista-targow
$event-padding: 10px;
$event-spacing: 10px; // Margin between events

// Colors
$blue:              #2277ee;
$light-blue:        #6f96f9;
$lighter-blue:      #90a9ff;
$orange:            #ffa959;
$light-orange:      #fda046;
$lighter-orange:    #ffb661;
// Greyscale
$black:             #060606;
$dark-grey:         #111111;
$default-grey:      #333333;
$grey:              #999999;
$light-grey:        #dddddd;
$lighter-grey:      #eeeeee;
$white:             #ffffff;
$shadow-grey:       rgba(0,0,0,.25);

// Universal mixins
@mixin breakpoint($point) {
    @if $point == sm {
        @media (min-width: $screen-sm-min) { @content; }
    }
    @else if $point == md {
        @media (min-width: $screen-md-min) { @content; }
    }
    @else if $point == lg {
        @media (min-width: $screen-lg-min) { @content; }
    }
}
@mixin transition($duration) {
    -webkit-transition: all $duration;
    -moz-transition: all $duration;
    transition: all $duration;
}
@mixin background-full($path) {
    background: url($path) no-repeat center center;
    background-size: cover;
}
@mixin linear-gradient($from, $to) {
    background: $from;
    background: -moz-linear-gradient(top, $from, $to);
    background: -webkit-linear-gradient(top, $from, $to);
    background: -o-linear-gradient(top, $from, $to);
    background: -ms-linear-gradient(top, $from, $to);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='$from',
        endColorstr='$to',
        GradientType=0
    );
    background: linear-gradient(top, $from, $to);
}
@mixin text-shadow($shadow) {
    -webkit-text-shadow: $shadow;
    -moz-text-shadow: $shadow;
    text-shadow: $shadow;
}
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    text-shadow: $shadow;
}
@mixin greyscale($switch: 1) {
    @if $switch == 1 {
        -webkit-filter: grayscale(100%); /* New WebKit */
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%); 
        -o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
        filter: gray; /* IE */
        -webkit-filter: grayscale(1); /* Old WebKit */
        filter: grayscale(100%); /* Current draft standard */
    }
    @else {
        -webkit-filter: grayscale(0%); /* New WebKit */
        -moz-filter: grayscale(0%);
        -ms-filter: grayscale(0%); 
        -o-filter: grayscale(0%); /* Not yet supported in Gecko, Opera or IE */
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
        filter: none; /* IE */
        -webkit-filter: grayscale(0); /* Old WebKit */
        filter: grayscale(0%); /* Current draft standard */
    }
}