// Following styles apply to weryfikacja-chinskiej-firmy.html only
#xc-verifyChineseFirm {

    .xc-chinskiraport {
        margin-top: 2*$default-spacing;
        margin-bottom: $default-spacing;
        text-align: center;
        //outline: 1px solid #ddd;
        img {
            max-height: 100px;
            //outline: 1px solid #eee;
        }
    }

    .xc-cta {
        margin-top: $default-spacing;
        .glyphicon { 
            font-size: 75%;
            opacity: .5;
        }
    }

    .xc-frame {
        @include box-shadow(0 0 10px $shadow-grey);
        margin-top: 65px;
        padding-top: 10px;
        overflow: hidden;
        outline: 1px solid #ddd;
        img {
            max-width: inherit;
            max-height: 400px;
        }
    }

}