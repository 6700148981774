// Following styles apply to partnerzy.html only
#xc-partners {

    h1 { margin-bottom: 40px; }

    .xc-content {
        width: 100%;
    }
    
    .xc-partners-container {
        margin-bottom: $default-spacing;
    }

    .logo-container {
        max-width: 100%;
        height: 150px;
        padding: 0;
        overflow: hidden;
        a { 
            display: block;
            max-width: 84%;
            width: 84%;
            height: 100%;
            margin: 0 auto;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
            color: #fff;
        }
        img {
            display: inline-block;
            vertical-align: middle;
            max-width: inherit;
            max-height: 125px;
        }
    }

}